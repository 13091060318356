//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    /**
     * Carousel image list: Ex: [https://statics.get-base.com/image]
     */
    images: {
      type: Array,
      default: () => []
    },
    /**
     * Carousel height
     */
    carouselHeight: {
      type: String,
      default: null
    },
    /**
     * Whether to show carousel next and prev buttons
     */
    controls: {
      type: Boolean,
      default: true
    },
    /**
     * Whether to show indicator dots
     */
    indicators: {
      type: Boolean,
      default: false
    },
    /**
     * Whether to animate the slide transition
     */
    noAnimation: {
      type: Boolean,
      default: false
    },
    /**
     * Make the carousel autoplay
     */
    autoplay: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      slide: 0,
      sliding: null
    }
  },
  computed: {
    cssVars () {
      return {
        '--carousel-height': this.carouselHeight || '100%'
      }
    }
  },
  watch: {
    slide () {
      this.$emit('slideChanged', this.slide)
    }
  },
  methods: {
    nextSlide () {
      this.$refs.carousel.next()
    },
    prevSlide () {
      this.$refs.carousel.prev()
    }
  }
}
