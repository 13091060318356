import { render, staticRenderFns } from "./testimonials-image.vue?vue&type=template&id=3dbd6f8c&"
import script from "./testimonials-image.vue?vue&type=script&lang=js&"
export * from "./testimonials-image.vue?vue&type=script&lang=js&"
import style0 from "./testimonials-image.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseTestimonialImage: require('/home/runner/work/webpage/webpage/components/base/testimonial-image/testimonial-image.vue').default,BaseCarousel: require('/home/runner/work/webpage/webpage/components/base/base-carousel/base-carousel.vue').default})
