//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      containerWidth: 0,
      carouselResponsive: {
        0: {
          items: 1,
          nav: false,
          dots: false,
          margin: 24
        },
        600: {
          items: 1,
          nav: false,
          dots: false
        }
      }
    }
  },
  computed: {
    testimonials () {
      return this.$t('general.testimonialsImage')
    },
    cssVars () {
      return {
        '--container-width': `${this.containerWidth}px`
      }
    }
  },
  mounted () {
    this.containerWidth = this.getContainerWidth()
    window.addEventListener('resize', () => {
      this.containerWidth = this.getContainerWidth()
    })
  },
  methods: {
    getContainerWidth () {
      if (!this.$refs.container) {
        return
      }
      return this.$refs.container.clientWidth
    },
    nextSlide () {
      this.$refs.carousel.nextSlide()
    },
    prevSlide () {
      this.$refs.carousel.prevSlide()
    },
    slideChanged (slide) {
      this.currentSlide = slide
    }
  }
}
