//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState('quiz', ['email']),
    ...mapGetters({
      isQuizPlanResults: 'product/isQuizPlanResults',
      getQuizResultsQueryParams: 'quiz/getQuizResultsQueryParams',
      isBaseImprovementPlan: 'product/isBaseImprovementPlan'
    })
  },
  methods: {
    trackQuizClicked () {
      this.addDDAction('Quiz clicked', { path: this.$route.path })
    }
  }
}
