//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      currentSlide: 0
    }
  },
  computed: {
    cssVars () {
      return {
        '--background-image': `url('${this.getStaticsObject(`/quiz/background_${this.currentSlide}.webp`)}')`
      }
    },
    items () {
      return this.$t('general.quizB.items')
    }
  },
  methods: {
    nextSlide () {
      this.$refs.carousel.nextSlide()
    },
    prevSlide () {
      this.$refs.carousel.prevSlide()
    },
    slideChanged (slide) {
      this.currentSlide = slide
    }
  }
}
