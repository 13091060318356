import { mapGetters, mapState } from 'vuex'
import GAEcommerce from '~/mixins/ga-ecommerce'

export default {
  mixins: [GAEcommerce],
  computed: {
    ...mapState('product', ['starterTestToAdd', 'starterTestIdToAdd', 'starterTest']),
    ...mapGetters({
      isQuizPlanResults: 'product/isQuizPlanResults',
      isBaseImprovementPlan: 'product/isBaseImprovementPlan',
      isBaseCompleteResultsPlan: 'product/isBaseCompleteResultsPlan'
    })
  },
  methods: {
    trackAddToCart ({ area, section = '', plan = 'base-monitor' }) {
      this.trackFBPixelEvent({
        event: 'AddToCart',
        properties: {
          plan,
          area
        }
      })
      this.addDDAction('Plan added to cart', {
        area,
        section,
        plan,
        path: this.$route.path
      })
      this.trackTikTokEvent({
        event: 'AddToCart',
        properties: {
          content_type: 'product',
          content_id: plan,
          content_name: plan
        }
      })
      this.trackGAEcommerceAddToCart({ area })
    },
    addMonitorToCart ({ area, section = '' }) {
      if (this.isQuizPlanResults || this.isBaseImprovementPlan) {
        return this.openRemoveQuizPlanModal(area)
      }
      const starterTest = this.starterTestToAdd || this.$t(`general.testOptions.options.${area}`)[0].title
      if (this.starterTestIdToAdd) {
        this.$store.dispatch('product/setStarterTestId', this.starterTestIdToAdd)
        this.$store.dispatch('product/addTestForGoalID', this.starterTestIdToAdd)
      }
      if (this.starterTestToAdd || !this.starterTest) {
        this.$store.dispatch('product/selectStarterTest', starterTest)
      }
      this.$store.dispatch('product/selectMonitorArea', area)
      this.trackAddToCart({ area, section })
    },
    async addQuizPlanToCart ({ plan = 'base-quiz-results', area, goalId, starterTest, starterTestId }) {
      await this.$store.dispatch('product/resetProducts')
      await this.$store.dispatch('product/setStarterTestId', starterTestId)
      await this.$store.dispatch('quiz/setGoalId', goalId)
      await this.$store.dispatch('product/selectStarterTest', starterTest)
      await this.$store.dispatch('product/selectPlan', plan)
      await this.$store.dispatch('product/selectMainArea', area)
      this.$store.commit('payment/setPaymentType', 'subscription')
      this.trackAddToCart({ area, plan })
    },
    openRemoveQuizPlanModal (area) {
      this.addDDAction('Remove quiz plan modal', {
        area,
        path: this.$route.path
      })
      this.$store.dispatch('product/selectAreaToAdd', area)
      this.$store.dispatch('product/selectPlanToAdd', 'base-monitor')
      this.$root.$emit('bv::show::modal', 'removeQuizPlanModal')
    }
  }
}
