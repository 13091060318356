//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PlansMixin from '~/mixins/plans'

export default {
  mixins: [PlansMixin],
  props: {
    /**
     * Area: diet | sex-drive | stress | energy | sleep
     */
    area: {
      type: String,
      required: true
    },
    /**
     * Test plan title
     */
    title: {
      type: String,
      required: true
    },
    /**
     * Test plan description
     */
    description: {
      type: String,
      required: true
    },
    /**
     * Test plan type: blood | saliva
     */
    type: {
      type: String,
      default: null
    },
    /**
     * Test plan show more text
     */

    /**
     * Test plan image
     */
    image: {
      type: String,
      required: true
    },
    /**
     * Test plan image
     */
    imageMobile: {
      type: String,
      required: true
    }
  },
  computed: {
    imageWrapper () {
      return 'div'
    }

  }
}
