//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'programs',
  computed: {
    items () {
      return this.$t('home.items')
    }
  },
  methods: {
    trackQuizClicked () {
      this.addDDAction('Quiz clicked', { path: this.$route.path })
    }
  }
}
