//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    /**
     * Testimonial description
     */
    description: {
      type: String,
      required: true
    },
    /**
     * Testimonial image
     */
    image: {
      type: String,
      required: true
    },
    /**
     * Testimonial author
     */
    author: {
      type: String,
      required: true
    },
    /**
     * Testimonial index
     */
    index: {
      type: Number,
      required: true
    }
  }
}

