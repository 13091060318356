var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"test-plans section"},[_c('div',{staticClass:"col-md-7 pb-md-40 pb-sm-24 mt-md-24"},[_c('div',{staticClass:"fs-32 pl-lg-16 ff-swis721greek-bt-bold lh-36 ls-3 pb-md-48 pb-sm-32 text-uppercase text-left",domProps:{"innerHTML":_vm._s(_vm.$t('home.programs.title'))}})]),_vm._v(" "),_c('div',{staticClass:"row flex-wrap pl-lg-32 pr-lg-32"},_vm._l((_vm.items),function(item,i){return _c('div',{key:item.title,staticClass:"col-lg-4 col-md-4 pl-lg-16 pr-lg-16",class:{
          'pt-lg-16': i > 2,
          'border-right-lg-black': i !== 2,
          'border-right-md-black': i % 2 === 0,
          'border-right-lg-none': i === 2,
          'pt-md-16': i > 1,
          'pt-sm-16': i > 0,
          'pt-lg-0': i < 3,
          'pt-md-0': i < 2
        }},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":item.showMore}},[_c('base-test-plan-basic',{class:['h-100', { 'border-bottom-black': i < 3 }],attrs:{"area":item.area,"title":item.title,"description":item.description,"image":_vm.getStaticsObject(("/home/" + (item.goalName) + "-box-clipped-2024.jpg")),"image-mobile":_vm.getStaticsObject(("/home/" + (item.goalName) + "-box-clipped-2024.jpg"))}})],1)])}),0),_vm._v(" "),_c('div',{staticClass:"mt-32 text-center"},[_c('div',{staticClass:"fs-20 ls-2 pb-md-24 pb-sm-24 text-uppercase text-center",domProps:{"innerHTML":_vm._s(_vm.$t('home.programs.subcta'))}}),_vm._v(" "),_c('base-button',{attrs:{"text":_vm.$t('home.floatingButton'),"theme":"dark","href":"/quiz","font":"lg"},on:{"click":_vm.trackQuizClicked}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }