//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    steps () {
      return this.$t('home.howItWorks.steps')
    }
  },
  methods: {
    trackQuizClicked () {
      this.addDDAction('Quiz clicked', { path: this.$route.path })
    }
  }
}
