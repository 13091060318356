var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"how-it-works-vertical mx-auto pt-md-40 pt-sm-32"},_vm._l((_vm.steps),function(step,index){return _c('div',{key:index,class:['d-flex flex-column justify-content-between align-items-center', {
      'flex-md-row': index % 2 === 0,
      'flex-md-row-reverse': index % 2 !== 0,
      'mt-md-56': index >= 0
    }]},[_c('div',{class:['how-it-works-vertical__step', {
        'text-md-start mr-md-56': index % 2 === 0,
        'text-md-end ml-md-56': index % 2 !== 0,
        'text-right':  index % 2 === 0
      }]},[_c('span',{staticClass:"how-it-works-vertical__step-number fs-25 ls-2 border-black ff-pixel-grotesk"},[_vm._v("\n        "+_vm._s(index + 1)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"fs-md-26 fs-sm-18 lh-md-32 lh-sm-22 ls-2 my-16 py-8",domProps:{"innerHTML":_vm._s(step.title)}}),_vm._v(" "),_c('div',{staticClass:"fs-16 lh-20 ls-3"},[_vm._v("\n        "+_vm._s(step.description)+"\n      ")])]),_vm._v(" "),_c('img',{staticClass:"d-block d-md-none mt-sm-56 mt-md-0 px-md-0",attrs:{"width":"250","src":step.image}}),_vm._v(" "),_c('img',{staticClass:"d-none d-md-block mt-sm-24 mt-md-0 px-24 px-md-0",attrs:{"width":"325","src":step.image}})])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }