//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex'

export default {
  props: {
    /**
     * Area page: diet | sex-drive | stress | energy | sleep
     */
    area: {
      type: String,
      required: true
    },
    /**
     * Biomarkers list for test landing pages
     */
    biomarkers: {
      type: String,
      default: null
    },
    /**
     * Goal name for the new quiz
     */
    goalName: {
      type: String,
      default: null
    },
    /**
     * Floating button text
     */
    text: {
      type: String,
      required: true
    },
    /**
     * Floating button text
     */
    href: {
      type: String,
      default: null
    },
    /**
     * Floating button theme button
     */
    buttonTheme: {
      type: String,
      default: 'dark'
    }
  },
  computed: {
    ...mapState('product', ['mainArea', 'planSelected']),
    title () {
      return this.$t('general.floatingButton.title', { area: this.goalName || this.biomarkers || this.area })
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
